import React, { Component } from 'react';
import AppAuthenticator from './Authentication/AppAuthenticator';
import Launch from './Launch/Launch';
import StacksApp from './Stacks/StacksApp';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


class App extends Component {

  constructor(props) {
    super(props);

    this.state = { isAuthenticated : false };
  }

  onAuthentication = (event) =>
  {
    if (!this.state.isAuthenticated){
      this.setState({isAuthenticated : true});
    }
  }

  render() {
    return (
      <div className="container">
        <AppAuthenticator onAuthSuccess={this.onAuthentication}/>

        {this.state.isAuthenticated && <Router>
          <div>
            <Switch>
              <Route path='/launch/:stackName'  component={Launch} />
              <Route path='/admin'  render={() => <StacksApp isAdminMode={true} /> }   />
              <Route path='/'  render={() => <StacksApp isAdminMode={false} /> }   />
            </Switch>
          </div>
        </Router>}
      </div>
    );
  }
}

export default App;
