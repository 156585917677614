
                    export const properties = {
                      baseApiUrl: 'https://7dk1k6lci0.execute-api.eu-west-1.amazonaws.com/Prod',
                      authDomain: 'vrt-core.auth.eu-west-1.amazoncognito.com',
                      redirectUrl: 'https://bringup.test.a51.be',
                      region: 'eu-west-1',
                      userPoolId: 'eu-west-1_QBt6fNXsi',
                      userPoolWebClientId: '55cejp3dj12ss4ajom35pceqi1',
                    };
                    
