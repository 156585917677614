import React from 'react';

const Stack = (props) => {
  return (
    <li>
      {
        props.isEditing &&
        <>
          <p name={props.index} className="glyphicon glyphicon-arrow-up" onClick={props.onMoveResourceUp(props.index, 1)}></p>
          <p name={props.index} className="glyphicon glyphicon-arrow-down" onClick={props.onMoveResourceDown(props.index, -1)}></p>
        </>
      }{' '}
      {props.resource_type} - {props.instanceRef}  [{props.region}] {' '}
      {
        props.isEditing &&
        <p name={props.index} className="glyphicon glyphicon-remove" onClick={props.onDelete}></p>
      }
    </li>
  );
}

export default Stack;

