import React from 'react';
import Stack from './Stack'


const StackList = (props) => {
  return (
  <div>
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Stack Info</th>
            <th>Components</th>
            <th>Last Launched</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.isAdding && <Stack {...props.stackToAdd} isEditing={true} isAdding={props.isAdding} isAdminMode={props.isAdminMode} onDuplicate={props.onDuplicate} onCancel={props.onCancelAdd} onConfirm={props.onConfirmAdd}/>}
          {props.stacks.map((stack) => <Stack {...stack} key={stack.name} isAdminMode={props.isAdminMode} onDuplicate={props.onDuplicate}/>)}
        </tbody>
      </table>
    </div>
  </div>
  );
}

export default StackList;
