import React, { Component } from 'react';
import Amplify, {Auth, Hub} from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import { properties } from '../properties.js';

const oauth = {
    // Domain name
    domain : properties.authDomain,

    // Authorized scopes
    scope : ['email','openid','aws.cognito.signin.user.admin'],

    // Callback URL
    redirectSignIn : properties.redirectUrl,

    // Sign out URL
    redirectSignOut : properties.redirectUrl,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'token',

    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag : false
    }
}

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: properties.region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: properties.userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: properties.userPoolWebClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        oauth: oauth
    }
});

class AppAuthenticator extends Component {
  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.signOut = this.signOut.bind(this);
    this.getLocalTime = this.getLocalTime.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen('auth', this);
    this.state = {
      authState: 'loading'
    }
  }

  componentDidMount() {
    // check the current session when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      console.log('user loaded')
      console.log(user);
      this.setState({authState: 'signedIn', email: user.attributes.email});
      this.props.onAuthSuccess();
    }).catch(e => {
      console.log('error while retrieving user (first try)');
      console.log(e);
      // not logged in (yet), try again
      Auth.currentAuthenticatedUser().then(user => {
          console.log('user loaded on second try')
          console.log(user);
          this.setState({authState: 'signedIn', email: user.attributes.email});
          this.props.onAuthSuccess();
        }).catch(e => {
          console.log(Auth);
          console.log('error while retrieving user (second try), redirecting to login flow');
          console.log(e);

          const config = Auth.configure();
          const {
            domain,
            redirectSignIn,
            responseType } = config.oauth;

          // not logged in, trigger login
          // add rest of url to 'state' param to preserve it for later
          var url_urlParts = require('url').parse(window.location.href, true);

          const clientId = config.userPoolWebClientId;
          // The url of the Cognito Hosted UI
          const url = 'https://' + domain + '/authorize?redirect_uri=' + redirectSignIn + '&response_type='
          + responseType + '&client_id=' + clientId + '&identity_provider=AzureAD'
          + "&state="+ url_urlParts.pathname;

          // Launch hosted UI
          window.location.assign(url);
        })
    })

    // see if the url contains a state parameter, if so, use it to redirec to the appropriate page
    const queryString = require('query-string');
    var parsed = queryString.parse(window.location.href);

    if (parsed.state){
        window.location.assign(properties.redirectUrl+parsed.state);
    }
  }

  onHubCapsule(capsule) {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          console.log('signed in');
          this.setState({authState: 'signedIn'});
          break;
        case 'signIn_failure':
          console.log('not signed in');
          this.setState({authState: 'loading'});
          break;
        default:
          break;
      }
    }
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({authState: 'signIn'});
    }).catch(e => {
      console.log(e);
    });
  }

  getLocalTime(time) {
    const today = new Date();
    const [hours, minutes] = time.split(':').map(Number);

    today.setHours(hours, minutes, 0, 0);

    return today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: "short" });
  }

  render() {
    return (
        <div className="jumbotron">
            {this.state.authState === 'loading' && (<div>Loading...</div>)}
            {
              this.state.authState === 'signedIn'
              && <div>
                <h2>Welcome {this.state.email}</h2>

                {/*
                  The bring down schedule is hardcoded in this landing page for user visibility.
                  These values are also defined in a cron job (CRON_SCHEDULE) located in in https://bitbucket.org/vrt-prod/bringup/src/main/cfn/bringdown.py.
                  It is crucial to keep the bring down values in this landing page and the cron job synchronized to avoid discrepancies.
                  Please ensure any updates to the bring down schedule are reflected in both locations.
                */}
                <p>Default bring down times: <strong>{this.getLocalTime('20:00')}</strong> & <strong>{this.getLocalTime('00:00')}</strong></p>

                <p><a class="btn btn-primary" href="https://vrt-prod.atlassian.net/wiki/spaces/DPCOPS/pages/3743680/Bringup+tool" role="button" target="_blank" rel="noopener noreferrer">more info</a></p>
              </div>
            }
        </div>
    );
            // signedIn state used to contain signout button, has been removed
            // <button className="btn btn-default" onClick={this.signOut}>Sign out</button>
  }
}

export default withOAuth(AppAuthenticator);
