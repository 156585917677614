export const types = [
  {id: -1, value: ''},
  {id: 0, value: 'Ec2', longname: 'Elastic Cloud Computing (ec2)'},
  {id: 1, value: 'RDS', longname: 'Relational Database (RDS)'},
  {id: 2, value: 'DBC', longname: 'RDS Database Cluster (DBC)'},
  {id: 3, value: 'CFn', longname: 'CloudFormation (CFn)'},
  {id: 4, value: 'ASG', longname: 'Automatic Scaling Group (ASG)'},
  {id: 5, value: 'ECS', longname: 'Elastic Container Service (ECS)'},
  {id: 999, value: 'Waiter', longname: 'Stack waiter to pause Bringup while previous resources are launching (not yet implemented)'} // TODO adjust longname when waiter has been implemented
]
