import React, { Component } from 'react';
import {types} from '../resourceTypeOptions.js'

class Stack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_type: "",
      region: "eu-west-1",
      instanceRef: ""
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  }

  handleAdd = event => {
    this.props.onAdd(this.state)(event);
    this.setState (
      {
        resource_type: "",
        region: "eu-west-1",
        instanceRef: ""
      }
    );
  }

  render(){
    return (
      <div>
        <label>
          Type
          <select value={this.state.resource_type} onChange={this.handleChange} name="resource_type">
            {types.map((option) => <option {...option} key={option.id} value={option.value}> {option.longname} </option>)}
          </select>
        </label>
          <input type="text" value={this.state.instanceRef} name="instanceRef" onChange={this.handleChange}
            className="form-control" placeholder="Resource reference. Instance Id."/>
          <input type="text" value={this.state.region} name="region" onChange={this.handleChange}
            className="form-control" placeholder="Enter region. Example: 'eu-west-1'"/>
          <button type="button" className="btn btn-default" onClick={this.handleAdd}
            disabled={!this.state.instanceRef.trim() || !this.state.resource_type.trim()}>
          <span className="glyphicon glyphicon-plus-sign"></span> Add item
        </button>
      </div>
    )
  }
}

export default Stack;
